import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({breakpoints, palette, spacing, fontSizes, layout}) => ({
    tagsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: spacing(2),
      paddingBottom: `${spacing(0)} !important`,
      '& span': {
        margin: `${spacing(0, 1, 0, 0)} !important`,
        padding: `${spacing(0)} !important`,
      },
      [breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      '& Button': {
        margin: 3,
        [breakpoints.down('sm')]: {
          margin: 8,
        },
      },
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    tags: {
      margin: spacing(0, 1, 0, 0),
      color: palette.primary.main,
      fontFamily: 'CamptonMedium',
      textTransform: 'none',
    },

    tag: {
      margin: spacing(0, 1, 0, 0),
      fontFamily: 'CamptonMedium',
      textTransform: 'none',
      fontSize: fontSizes.small,
      maxHeight: layout.tags.maxHeight,
      display: 'flex', // Center text
      justifyContent: 'center', // Center text horizontally
      alignItems: 'center', // Center text vertically
    },

    isPrimary: {
      color: palette.primary.main,
      backgroundColor: palette.tags.departments.backgroundColor,
    },

    isSecondary: {
      color: palette.secondary.main,
      backgroundColor: palette.tags.industries.backgroundColor,
    },

    isTertiary: {
      color: palette.tertiary.main,
      backgroundColor: palette.tags.partners.backgroundColor,
    },

    isQuaternary: {
      color: palette.quaternary.main, // Light contrast color
      backgroundColor: '#cce0ff', // Adjust background color as needed
    },

    isGrey: {
      color: 'black',
      backgroundColor: palette.background.contrastGrey,
    },
  }),
)

export default useStyles
