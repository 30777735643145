import {node, shape} from 'prop-types'
import React from 'react'

import {heroDetailsPropTypes} from 'helpers/propTypes'
import BackButton from 'components/UI/BackButton'
import Description from 'components/UI/Description'
import HashTags from 'components/UI/HashTags'
import Title from 'components/UI/Title'

import useStyles from './styles'

const HeroDetails = ({backLink, backLabel, name, description, icon, tags2}) => {
  const classes = useStyles()

  return (
    <>
      <BackButton
        backLink={backLink}
        backLabel={backLabel}
        positionAbsolute
        isVisibleOnMobile
      />
      <div className={classes.wrapper}>
        <div className={classes.textBlock}>
          <Title variant="h1">{name}</Title>
          {tags2 && <HashTags tags={tags2} hasColormapping />}
          <Description isJustified>{description}</Description>
        </div>
        <div className={classes.iconWrap}>
          <img
            src={icon.file.url}
            alt={icon.description}
            className={classes.icon}
            loading="lazy"
          />
        </div>
      </div>
    </>
  )
}

HeroDetails.propTypes = heroDetailsPropTypes
HeroDetails.defaultProps = shape({
  backLink: '',
  backLabel: '',
  name: '',
  description: '',
  icon: node,
  tags: [''],
})

export default HeroDetails
