import * as R from 'ramda'
import {arrayOf, bool, objectOf, string, number} from 'prop-types'
import {Button} from '@mui/material'
import {navigate} from 'gatsby'
import classNames from 'classnames'
import React from 'react'

import useStyles from './styles'

const HashTags = ({
  tags,
  hasColormapping,
  isDisabled,
  isGrey,
  noHash,
  onTagClick,
  useNewVersion,
  maxTags,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  const limitedTags =
    typeof maxTags === 'number' ? tags.slice(0, maxTags) : tags

  const renderTagName = tagName => {
    if (noHash) {
      return tagName.replace(/#/g, '');
    }

    return tagName.includes('#') ? tagName : `#${tagName}`
  }

  return (
    <span className={classes.tagsContainer}>
      {mapIndexed((tag, mapIndex) => {
        const moduloIndex = mapIndex % 4

        return (
          <Button
            style={{pointerEvents: isDisabled && 'none'}}
            className={classNames(
              classes.tag,
              {[classes.isGrey]: isGrey},
              hasColormapping && {
                [classes.isPrimary]: moduloIndex === 0,
                [classes.isSecondary]: moduloIndex === 1,
                [classes.isTertiary]: moduloIndex === 2,
                [classes.isQuaternary]: moduloIndex === 3,
              },
            )}
            role="button"
            tabIndex={0}
            size="small"
            onClick={() => {
              if (useNewVersion) {
                onTagClick(
                  `tag-${tag.name.name}` || `tag-${tag.name}` || `tag-${tag}`,
                )
              } else {
                navigate(
                  `../../playbook#/tag-${renderTagName(
                    tag.name.name || tag.name || tag,
                  )}`,
                )
              }
            }}
          >
            {renderTagName(tag.name.name || tag.name || tag)}
          </Button>
        )
      }, limitedTags)}
    </span>
  )
}

HashTags.propTypes = {
  hasColormapping: bool,
  isDisabled: bool,
  maxTags: number,
  noHash: bool,
  tags: arrayOf(objectOf({name: string})),
}
HashTags.defaultProps = {
  hasColormapping: false,
  isDisabled: false,
  maxTags: undefined,
  noHash: false,
  tags: [],
}

export default HashTags
