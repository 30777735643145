import {bool, string} from 'prop-types'
import {Button} from '@mui/material'
import {Link} from 'gatsby'
import classNames from 'classnames'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import React from 'react'

import useStyles from './styles'

const BackButton = ({
  color,
  backLabel,
  backLink,
  positionAbsolute,
  isVisibleOnMobile,
}) => {
  const classes = useStyles()

  return (
    <Link className={classes.backLink} to={backLink}>
      <Button
        className={classNames(classes.backLink, {
          [classes.positionRelative]: !positionAbsolute || isVisibleOnMobile,
          [classes.positionAbsolute]: positionAbsolute && !isVisibleOnMobile,
          [classes.isVisibleOnMobile]: isVisibleOnMobile,
          [classes.isSecondary]: color === 'secondary',
        })}
        startIcon={<KeyboardBackspaceIcon />}
      >
        {backLabel}
      </Button>
    </Link>
  )
}

BackButton.propTypes = {
  backLabel: string,
  backLink: string,
  color: string,
  isVisibleOnMobile: bool,
  positionAbsolute: bool,
}
BackButton.defaultProps = {
  backLabel: '',
  backLink: '',
  color: '',
  isVisibleOnMobile: false,
  positionAbsolute: false,
}

export default BackButton
