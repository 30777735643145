import * as R from 'ramda'
import {graphql} from 'gatsby'
import loadable from '@loadable/component'
import React from 'react'

import {formRedirection} from 'helpers/utils'
import {getServices} from 'helpers/services'
import Article from 'components/Insight/Article'
import HeroDetails from 'components/Services/HeroDetails'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const subLevelServices = ({pageContext, data, location}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const {countryCode, navLocale, salesforce, services} = countryData

  const Related = loadable(() => import('components/UI/Related'))
  const SwitchForm = loadable(() => import('components/UI/SwitchForm'))

  const {
    metaTitle,
    metaDescription,
    name,
    imageOrIcon,
    relatedEntries,
    shortDescription,
    longDescription,
    isKeyPlaysDisplayed,
    tags,
    tags2,
  } = R.pathOr(null, ['contentfulService'], data)

  const relatedKeyplays = {
    relatedKeyPlaysTitle: R.pathOr(
      '',
      ['relatedKeyPlaysTitle'],
      relatedEntries,
    ),
    relatedKeyPlaysShortDescription: R.pathOr(
      '',
      ['relatedKeyPlaysShortDescription', 'relatedKeyPlaysShortDescription'],
      relatedEntries,
    ),
    relatedKeyPlaysCallToAction: R.pathOr(
      '',
      ['relatedKeyPlaysCallToAction'],
      relatedEntries,
    ),
  }

  const relatedInsights = {
    relatedUpdatesTitle: R.pathOr('', ['relatedUpdatesTitle'], relatedEntries),
    relatedUpdatesShortDescription: R.pathOr(
      '',
      ['relatedUpdatesShortDescription', 'relatedUpdatesShortDescription'],
      relatedEntries,
    ),
    relatedUpdatesCallToAction: R.pathOr(
      '',
      ['relatedUpdatesCallToAction'],
      relatedEntries,
    ),
  }

  const relatedService = {
    relatedServicesTitle: R.pathOr(
      '',
      ['relatedServicesTitle'],
      relatedEntries,
    ),
    relatedServicesShortDescription: R.pathOr(
      '',
      ['relatedServicesShortDescription', 'relatedServicesShortDescription'],
      relatedEntries,
    ),
    relatesServicesCallToAction: R.pathOr(
      '',
      ['relatesServicesCallToAction'],
      relatedEntries,
    ),
  }

  const formData = R.pathOr(null, ['contentfulForm'], data)

  const keyPlays = R.map(({node}) => node, data.allContentfulKeyPlay.edges)

  const Insights = R.pathOr(null, ['allContentfulInsight', 'edges'], data)

  const topLevelServices = services && services.topLevelServices
  const relatedServices = R.filter(
    node => node !== undefined,
    R.flatten(
      R.map(node => node.subLevelServices, getServices(data, topLevelServices)),
    ),
  )

  const {backButton} = R.pathOr(
    null,
    ['allContentfulServices', 'nodes', 0],
    data,
  )
  const siteURL = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)

  const formRedirect = formRedirection(siteURL, countryCode, navLocale, 'lead')

  const richTextDocuments = R.pathOr(null, ['richTextDocuments', 'nodes'], data)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasGreyBackground hasPaddingTop={false} hasPaddingBottom={false}>
        {imageOrIcon && (
          <HeroDetails
            backLink="../../services/"
            backLabel={backButton}
            name={name}
            description={R.pathOr(' ', ['shortDescription'], shortDescription)}
            icon={imageOrIcon}
            tags={tags}
            tags2={tags2}
          />
        )}
      </Section>
      {longDescription && (
        <Section hasPaddingBottom={false} hasVerySmallPadding>
          <Article
            content={longDescription}
            hasPaddingBottom={false}
            richTextDocuments={richTextDocuments}
          />
        </Section>
      )}
      {isKeyPlaysDisplayed && (
        <Related
          cards={keyPlays}
          title={relatedKeyplays.relatedKeyPlaysTitle}
          description={relatedKeyplays.relatedKeyPlaysShortDescription}
          ctaTitle={relatedKeyplays.relatedKeyPlaysCallToAction}
          ctaLink="../../playbook"
          buttonsPosition="center"
          prefix="../../playbook/"
        />
      )}
      <SwitchForm
        data={formData}
        location={location}
        locale={navLocale}
        redirection={formRedirect}
        redirect={formRedirect}
        websiteSource="services"
        hubSpotCountry={salesforce}
        isArticle
      />
      {Insights && (
        <Related
          cards={Insights}
          title={relatedInsights.relatedUpdatesTitle}
          description={relatedInsights.relatedUpdatesShortDescription}
          ctaTitle={relatedInsights.relatedUpdatesCallToAction}
          ctaLink="../../insights"
          cardSize="wide"
          buttonsPosition="bottom"
          prefix="../../insights/"
        />
      )}
      {relatedServices && (
        <Related
          cards={relatedServices}
          title={relatedService.relatedServicesTitle}
          description={relatedService.relatedServicesShortDescription}
          ctaTitle={relatedService.relatesServicesCallToAction}
          ctaLink="../"
          buttonsPosition="bottom"
          prefix="../"
          isServices
        />
      )}
    </>
  )
}

export default subLevelServices

export const pageQuery = graphql`
  query templateService(
    $entityRegEx: String
    $nodeLocale: String
    $contentId: String
    $technicalName: String
  ) {
    contentfulService(
      node_locale: {eq: $nodeLocale}
      contentful_id: {eq: $contentId}
    ) {
      imageOrIcon {
        file {
          url
        }
      }
      name
      metaTitle
      metaDescription {
        metaDescription
      }
      shortDescription {
        shortDescription
      }
      longDescription {
        raw
        references {
          ... on ContentfulAsset {
            description
            contentful_id
            file {
              url
              contentType
            }
            __typename
          }
          ... on ContentfulEntry {
            contentful_id
            __typename
            ... on ContentfulDocument {
              name
            }
            ... on ContentfulCallToAction {
              contentful_id
              __typename
              callToActionText
              callToActionLink
            }
          }
        }
      }
      isKeyPlaysDisplayed
      relatedEntries {
        name
        relatedKeyPlaysCallToAction
        relatedKeyPlaysShortDescription {
          relatedKeyPlaysShortDescription
        }
        relatedKeyPlaysTitle
        relatedUpdatesCallToAction
        relatedUpdatesShortDescription {
          relatedUpdatesShortDescription
        }
        relatedUpdatesTitle
        relatesServicesCallToAction
        relatedServicesShortDescription {
          relatedServicesShortDescription
        }
        relatedServicesTitle
      }
      tags
      tags2 {
        name {
          name
        }
      }
    }
    allContentfulServices(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        backButton
      }
    }
    allContentfulKeyPlay(
      filter: {
        node_locale: {eq: $nodeLocale}
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
        services2: {elemMatch: {contentful_id: {eq: $contentId}}}
      }
    ) {
      edges {
        node {
          contentful_id
          id
          title {
            title
          }
          slug
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    contentfulForm(
      name: {regex: $entityRegEx}
      node_locale: {eq: $nodeLocale}
    ) {
      ...FormFields
    }
    allContentfulInsight(
      filter: {
        country3: {elemMatch: {technicalName: {eq: $technicalName}}}
        node_locale: {eq: $nodeLocale}
      }
      sort: {publicationDate: DESC}
      limit: 20
    ) {
      edges {
        node {
          ...insightFields
          metaDescription {
            metaDescription
          }
          image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            description
          }
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
      salesforce
      services {
        topLevelServices {
          entryId
          subLevelServices {
            entryId
          }
        }
      }
    }
    allContentfulService(
      filter: {node_locale: {eq: $nodeLocale}, contentful_id: {ne: $contentId}}
    ) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    richTextDocuments: allContentfulDocument(
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        contentful_id
        name
      }
    }
  }
`
